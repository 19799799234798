// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-additions-js": () => import("./../../../src/pages/additions.js" /* webpackChunkName: "component---src-pages-additions-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-management-js": () => import("./../../../src/pages/management.js" /* webpackChunkName: "component---src-pages-management-js" */),
  "component---src-pages-new-construction-services-js": () => import("./../../../src/pages/new-construction-services.js" /* webpackChunkName: "component---src-pages-new-construction-services-js" */),
  "component---src-pages-remodels-js": () => import("./../../../src/pages/remodels.js" /* webpackChunkName: "component---src-pages-remodels-js" */)
}

